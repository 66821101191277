<template>
  <div class="container">
    <h1 class="xl-title">
      {{ t("404.title.first") }}<br />{{ t("404.title.second") }}
    </h1>
    <router-link
      :to="{
        name: 'Art',
        params: { locale: `${$route.params.locale}`, trans: true },
      }"
    >
      {{ t("404.button") }}
    </router-link>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "404",
  setup() {
    const { t } = useI18n();
    return { t };
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
h1 {
  max-width: 80%;
  text-align: center;
}
a {
  margin-top: 8rem;
  background: rgba(0, 0, 0, 0.1);
  padding: 20px 30px;
  color: #6a605b;
}
</style>
